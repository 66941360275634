import axios from 'axios';
import React, { createContext, useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export const GlobalData = createContext();
import MyPro from './MyPro';
import Ledger from './Ledger';
import Sale from './Sale/Sale';
import PaymentIN from './Receipt/PaymentIN';
import Purchase from './Purchase/Purchase';
import PaymentOut from './Payment/PaymentOut';
import CreditNote from './CreditNote/CreditNote';
import DebitNote from './DebitNote/DebitNote';
import Setting from './Setting';
import Journal from './Journal';
import Profile from './Profile';
import StockJournal from './StockJournal';
import Mainpage from './Mainpage';
import GlobalKeyListener from '../GlobalKeyListener';
import { useUser } from '../Authdata';
// import './Dashboard.css';
function Dashboard(props) {
    const [url, setUrl] = useState(`${props.url}`);
    const { auth, Authclear, reloadpage } = useUser();
    const [myComponent, setMyComponent] = useState("");
    const [profileupdate, setProfileUpdate] = useState(0);
    // const setting = JSON.parse(localStorage.getItem("setting"));
    const [setting_bosno, setSetting_Bosno] = useState(auth && auth.bosno ? auth.bosno : "automatic");
    const [settingcontrol, setSettingControl] = useState(true);
    // const [discount, setDiscount] = useState(auth && auth.discount ? auth.discount : "no");
    const [invoicechanger, setInvoiceChanger] = useState(auth && auth.invoice ? auth.invoice : "invoice-1");
    const redirect = useNavigate();
    const navButton = useRef(null);
    const linksContainerRef = useRef(null);
    const [activeLink, setActiveLink] = useState("");
    const [invoicedescription, setInvoiceDescription] = useState(auth && auth.invoicedescription
        ? auth.invoicedescription
        : "");
    const [settingNav, setSettingnav] = useState("functions");
    const [alignMentMangment, setAlignMentMangment] = useState({
        logo: "",
        firm_name: "",
        address: "",
        show_logo: "",
        show_firm_name: "",
        show_address: "",
        show_desc: ""

    })
    useEffect(() => {
        reloadpage();
        setMyComponent("");
    }, [profileupdate, useUser])
    const logout = () => {
        localStorage.clear();
        Authclear();
        redirect("/");
    }
    const SettingSetup = () => {
        let data = {
            frm_name: auth.frm_name,
            fssai: auth.fssai,
            udyam: auth.udyam,
            mobile: auth.mobile,
            address: auth.address,
            email: auth.email,
            password: auth.password,
            logo: auth.logo,
            date: auth.date,
            bosno: setting_bosno,
            invoice: invoicechanger,
            invoicedescription: invoicedescription
        }
        axios.put(`${url}/userupdate/${auth.id}`, data).then((res) => {
            if (res.data) {
                localStorage.setItem("setting", JSON.stringify({
                    bosno: setting_bosno,
                    invoice: invoicechanger,
                    invoicedescription: invoicedescription,
                    invoiceAlignment: alignMentMangment
                }));
                setSettingControl(!settingcontrol)
            }
        })

    }
    function setNavbar(val) {
        setMyComponent(val)
        navButton.current.classList.add("collapsed");
        linksContainerRef.current.classList.remove("show");
    }
    const handleNavClick = (navItem) => {
        setActiveLink(navItem);
        setNavbar(navItem); // Assuming setNavbar is a function passed as a prop or defined elsewhere
    };

    // console.log(auth);
    const alignmentMangmentHandal = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setAlignMentMangment((prev) => {
            return { ...prev, [name]: value }
        })
    }

    return (
        <div>
            <GlobalKeyListener />
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-sm-12 bg-light'>
                        <nav className="navbar navbar-expand-lg navbar-primary bg-light text-dark">
                            <div className="container-fluid">
                                <img src={`${props.url}/upload/${auth && auth.logo}`} height={"50px"} alt="Logo"></img>&nbsp;
                                {/* <Link className="navbar-brand" to="#">{auth && auth.hotel_name}</Link> */}
                                <Link className="navbar-brand text-decoration-underline" onClick={() => handleNavClick("")}><h3>{auth && auth.frm_name}</h3></Link>
                                <button ref={navButton} className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div ref={linksContainerRef} className="collapse navbar-collapse" id="navbarSupportedContent">
                                    <div className="navbar-nav me-auto mb-2 mb-lg-0"></div>
                                    {/* <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                        <li className="nav-item">
                                            <button
                                                className={`nav-link btn custom-btn ${activeLink === "" ? "custom-active" : ""}`}
                                                onClick={() => handleNavClick("")}
                                            >
                                                Dashboard
                                            </button>
                                        </li>
                                        <li className="nav-item">
                                            <button
                                                className={`nav-link btn custom-btn ${activeLink === "sale" ? "custom-active" : ""}`}
                                                onClick={() => handleNavClick("sale")}
                                            >
                                                Sale
                                            </button>
                                        </li>

                                        <li className="nav-item d-flex">
                                            <button
                                                className={`nav-link btn custom-btn ${activeLink === "paymentin" ? "custom-active " : ""}`}
                                                onClick={() => handleNavClick("paymentin")}
                                            >
                                                Receipt
                                            </button>
                                        </li>
                                        <li className="nav-item d-flex">
                                            <button
                                                className={`nav-link btn custom-btn ${activeLink === "purchase" ? "custom-active " : ""}`}
                                                onClick={() => handleNavClick("purchase")}
                                            >
                                                Purchase
                                            </button>
                                        </li>
                                        <li className="nav-item d-flex">
                                            <button
                                                className={`nav-link btn custom-btn ${activeLink === "paymentout" ? "custom-active " : ""}`}
                                                onClick={() => handleNavClick("paymentout")}
                                            >
                                                Payment
                                            </button>
                                        </li>
                                        <li className="nav-item d-flex">
                                            <button
                                                className={`nav-link btn custom-btn ${activeLink === "creditnote" ? "custom-active" : ""}`}
                                                onClick={() => handleNavClick("creditnote")}
                                            >
                                                Credit Note
                                            </button>
                                        </li>
                                        <li className="nav-item d-flex">
                                            <button
                                                className={`nav-link btn custom-btn ${activeLink === "debitnote" ? "custom-active " : ""}`}
                                                onClick={() => handleNavClick("debitnote")}
                                            >
                                                Debit Note
                                            </button>
                                        </li>
                                        <li className="nav-item d-flex d-md-inline d-none">
                                            <button
                                                className={`nav-link btn custom-btn ${activeLink === "journal" ? "active text-primary fw-bold" : ""}`}
                                                onClick={() => handleNavClick("journal")}
                                            >
                                                Journal
                                            </button>
                                        </li>
                                        <li className="nav-item d-flex">
                                            <button
                                                className={`nav-link btn custom-btn ${activeLink === "inventory" ? "active text-primary fw-bold" : ""}`}
                                                onClick={() => handleNavClick("inventory")}
                                            >
                                                Inventory
                                            </button>
                                        </li>
                                        <li className="nav-item d-flex d-md-inline d-none">
                                            <button
                                                className={`nav-link btn custom-btn ${activeLink === "stock-journal" ? "active text-primary fw-bold" : ""}`}
                                                onClick={() => handleNavClick("stock-journal")}
                                            >
                                                Stock Journal
                                            </button>
                                        </li>
                                    </ul> */}

                                    <div className="nav-item d-flex">
                                        <div className='me-2'>
                                            <button className="nav-link" data-bs-toggle="modal" data-bs-target="#setting"><i className="bi bi-gear">&nbsp;</i>Settings</button>
                                        </div>
                                        <div className='me-2'>
                                            <Link className="nav-link" to={"/"} onClick={logout}><i className="bi bi-box-arrow-right"></i>&nbsp;Logout</Link>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </nav>
                    </div>
                    <div className="col-sm-12 bg-primary">
                        <ul className='d-md-flex' style={{ marginBottom: "-1px" }}>
                            {[
                                { name: "Dashboard", link: "" },
                                { name: "Sale", link: "sale" },
                                { name: "Receipt", link: "paymentin" },
                                { name: "Purchase", link: "purchase" },
                                { name: "Payment", link: "paymentout" },
                                { name: "Credit Note", link: "creditnote" },
                                { name: "Debit Note", link: "debitnote" },
                                { name: "Journal", link: "journal" },
                                { name: "Inventory", link: "inventory" },
                                { name: "Stock Journal", link: "stock-journal" }
                            ].map((item) => (
                                <li className="d-flex text-decoration-none" key={item.link} style={{ flex: 1 }}>
                                    <button
                                        className={`btn custom-btn w-100 ${activeLink === item.link ? "custom-active" : ""}`}
                                        onClick={() => handleNavClick(item.link)}
                                    >
                                        {item.name}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>

                </div>
                <div className='row'>
                    <div className='col-md-12  bg-light vh-100 brder'>
                        <nav className="navbar navbar-expand-lg navbar-light bg-light"></nav>
                        <div className='container-fluid bg-white rounded shadow'>

                            {
                                myComponent == "ledger" ? <Ledger url={url} /> : myComponent == "sale" ? <Sale url={url} bosno={setting_bosno} setting={settingcontrol} /> : myComponent == "paymentin" ? <PaymentIN url={url} setting={settingcontrol} /> : myComponent == 'purchase' ? <Purchase url={url} bosno={setting_bosno} setting={settingcontrol} /> : myComponent == 'paymentout' ? <PaymentOut url={url} /> : myComponent == 'creditnote' ? <CreditNote url={url} bosno={setting_bosno} setting={settingcontrol} /> : myComponent == "debitnote" ? <DebitNote url={url} bosno={setting_bosno} setting={settingcontrol} /> : myComponent == "journal" ? <Journal url={url} /> : myComponent == "inventory" ? <MyPro url={url} /> : myComponent == "stock-journal" ? <StockJournal url={url} /> :
                                    <Mainpage url={url} />
                            }

                        </div>
                    </div>
                </div>

            </div>
            <div class="modal fade" id="setting" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Settings</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div className='row '>
                                <div className='col-md-4 border-end'>
                                    <table class="table">

                                        <tbody>
                                            <tr>
                                                <td><Link style={{ textDecoration: "none" }} onClick={() => setSettingnav("functions")}>Functions</Link></td>
                                            </tr>
                                            <tr>
                                                <td><Link style={{ textDecoration: "none" }} onClick={() => setSettingnav("invoice-template")}>Invoice Template</Link></td>
                                            </tr>
                                            <tr>
                                                <td><Link style={{ textDecoration: "none" }} onClick={() => setSettingnav("invoice-print-configuration")}>Invoice Print Configuration</Link></td>
                                            </tr>
                                            <tr>
                                                <td><Link style={{ textDecoration: "none" }} onClick={() => setSettingnav("short-cut-keys")}>ShortCut List</Link></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className='col-md-8'>
                                    {
                                        settingNav == "functions" ? <div className='row'>
                                            <div className='col-md-12'>
                                                <div class="mb-4 row">
                                                    <label class="col-sm-4 col-form-label">Bill Of Supply</label>
                                                    <div class="col-sm-8">
                                                        <div class="form-check form-check-inline">
                                                            <input class="form-check-input" type="radio" name="bosno" id="Automatic" checked={setting_bosno == "automatic" ? true : false} value={"automatic"} onChange={(e) => setSetting_Bosno(e.target.value)}></input>
                                                            <label class="form-check-label" for="Automatic">
                                                                Automatic
                                                            </label>
                                                        </div>
                                                        <div class="form-check form-check-inline">
                                                            <input class="form-check-input" type="radio" name="bosno" id="Manual" checked={setting_bosno == "manual" ? true : false} value={"manual"} onChange={(e) => setSetting_Bosno(e.target.value)}></input>
                                                            <label class="form-check-label" for="Manual">
                                                                Manual
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='mb-4 row '>

                                                    <div className='col-12 mt-1'>
                                                        <label>Invoice Description</label>
                                                        <textarea className='form-control' onChange={(e) => setInvoiceDescription(e.target.value)} >{invoicedescription}</textarea>
                                                    </div>
                                                    <div className='d-flex'>
                                                        <label class="col-sm-4 col-form-label">Logo</label>
                                                        <div class="col-sm-8">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="radio" name="logo" id="logo_left" value={"start"} checked={alignMentMangment.logo == "start" ? true : false} onChange={alignmentMangmentHandal} ></input>
                                                                <label class="form-check-label" for="logo_left">
                                                                    Left
                                                                </label>
                                                            </div>
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="radio" name="logo" id="logo_center" value={"center"} checked={alignMentMangment.logo == "center" ? true : false} onChange={alignmentMangmentHandal}></input>
                                                                <label class="form-check-label" for="logo_center">
                                                                    Center
                                                                </label>
                                                            </div>
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="radio" name="logo" id="logo_right" value={"end"} checked={alignMentMangment.logo == "end" ? true : false} onChange={alignmentMangmentHandal}></input>
                                                                <label class="form-check-label" for="logo_right">
                                                                    Right
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='d-flex'>
                                                        <label class="col-sm-4 col-form-label">Firm Name</label>
                                                        <div class="col-sm-8">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="radio" name="firm_name" id="firm_name_left" value={"start"} checked={alignMentMangment.firm_name == "start" ? true : false} onChange={alignmentMangmentHandal}></input>
                                                                <label class="form-check-label" for="firm_name_left">
                                                                    Left
                                                                </label>
                                                            </div>
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="radio" name="firm_name" id="firm_name_center" value={"center"} checked={alignMentMangment.firm_name == "center" ? true : false} onChange={alignmentMangmentHandal}></input>
                                                                <label class="form-check-label" for="firm_name_center">
                                                                    Center
                                                                </label>
                                                            </div>
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="radio" name="firm_name" id="firm_name_right" value={"end"} checked={alignMentMangment.firm_name == "end" ? true : false} onChange={alignmentMangmentHandal}></input>
                                                                <label class="form-check-label" for="firm_name_right">
                                                                    Right
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='d-flex'>
                                                        <label class="col-sm-4 col-form-label">Address</label>
                                                        <div class="col-sm-8">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="radio" name="address" id="address_left" value={"start"} checked={alignMentMangment.address == "start" ? true : false} onChange={alignmentMangmentHandal}></input>
                                                                <label class="form-check-label" for="address_left">
                                                                    Left
                                                                </label>
                                                            </div>
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="radio" name="address" id="address_center" value={"center"} checked={alignMentMangment.address == "center" ? true : false} onChange={alignmentMangmentHandal}></input>
                                                                <label class="form-check-label" for="address_center">
                                                                    Center
                                                                </label>
                                                            </div>
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="radio" name="address" id="address_right" value={"end"} checked={alignMentMangment.address == "end" ? true : false} onChange={alignmentMangmentHandal}></input>
                                                                <label class="form-check-label" for="address_right">
                                                                    Right
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='d-flex'>
                                                        <label class="col-sm-4 col-form-label">Show Desciption</label>
                                                        <div class="col-sm-8">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="radio" name="show_desc" id="show_desc_show" value={"block"} onChange={alignmentMangmentHandal}></input>
                                                                <label class="form-check-label" for="show_desc_show">
                                                                    Show
                                                                </label>
                                                            </div>
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="radio" name="show_desc" id="show_desc_hide" value={"none"} onChange={alignmentMangmentHandal}></input>
                                                                <label class="form-check-label" for="show_desc_hide">
                                                                    hide
                                                                </label>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className='d-flex'>
                                                        <label class="col-sm-4 col-form-label">Show Logo</label>
                                                        <div class="col-sm-8">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="radio" name="show_logo" id="show_logo_show" value={"block"} onChange={alignmentMangmentHandal}></input>
                                                                <label class="form-check-label" for="show_logo_show">
                                                                    Show
                                                                </label>
                                                            </div>
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="radio" name="show_logo" id="show_logo_hide" value={"none"} onChange={alignmentMangmentHandal}></input>
                                                                <label class="form-check-label" for="show_logo_hide">
                                                                    hide
                                                                </label>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className='d-flex'>
                                                        <label class="col-sm-4 col-form-label">Show Firm Name</label>
                                                        <div class="col-sm-8">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="radio" name="show_firm_name" id="show_firm_name_show" value={"block"} onChange={alignmentMangmentHandal}></input>
                                                                <label class="form-check-label" for="show_firm_name_show">
                                                                    Show
                                                                </label>
                                                            </div>
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="radio" name="show_firm_name" id="show_firm_name_hide" value={"none"} onChange={alignmentMangmentHandal}></input>
                                                                <label class="form-check-label" for="show_firm_name_hide">
                                                                    hide
                                                                </label>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className='d-flex'>
                                                        <label class="col-sm-4 col-form-label">Show Adress</label>
                                                        <div class="col-sm-8">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="radio" name="show_address" id="show_address_show" value={"block"} onChange={alignmentMangmentHandal}></input>
                                                                <label class="form-check-label" for="show_address_show">
                                                                    Show
                                                                </label>
                                                            </div>
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="radio" name="show_address" id="show_address_hide" value={"none"} onChange={alignmentMangmentHandal}></input>
                                                                <label class="form-check-label" for="show_address_hide">
                                                                    hide
                                                                </label>
                                                            </div>

                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                        </div> : settingNav == "invoice-template" ? <div className='col-md-12'>
                                            <div class="mb-4 row">
                                                <div className='col-md-4 mt-1'>
                                                    <input type="radio" className="btn-check" id="invoice-1" name='invoice' value={"invoice-1"} autoComplete="off" checked={invoicechanger == "invoice-1" ? true : false} onChange={(e) => setInvoiceChanger(e.target.value)}></input>
                                                    <label className="btn btn-primary w-100" htmlFor="invoice-1">
                                                        <div className="d-flex align-items-center mt-1">
                                                            <div className="flex-shrink-0">
                                                                <img src={`http://localhost:3000/invoice1.jpg`} alt="..." height={"150px"} width={"200px"} ></img>
                                                            </div>
                                                        </div>
                                                    </label>
                                                </div>
                                                <div className='col-md-4 mt-1'>
                                                    <input type="radio" className="btn-check" id="invoice-2" name='invoice' value={"invoice-2"} autoComplete="off" checked={invoicechanger == "invoice-2" ? true : false} onChange={(e) => setInvoiceChanger(e.target.value)}></input>
                                                    <label className="btn btn-primary w-100" htmlFor="invoice-2">
                                                        <div className="d-flex align-items-center mt-1">
                                                            <div className="flex-shrink-0">
                                                                <img src={`http://localhost:3000/invoice2.png`} alt="..." height={"150px"} width={"200px"} ></img>
                                                            </div>
                                                        </div>
                                                    </label>
                                                </div>

                                            </div>
                                        </div> : settingNav == "short-cut-keys" ? <div className='col-md-12'>
                                            <div className='row mb-4'>
                                                <table class="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">ShortCut Key</th>
                                                            <th scope="col">Desciption of shortcut key</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">Esc</th>
                                                            <td>Back to page</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Ctrl+S</th>
                                                            <td>Save to page</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Ctrl+P</th>
                                                            <td>Print Invoice</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Alt+N</th>
                                                            <td>New Invoice</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div> : null

                                    }


                                </div>

                            </div>



                        </div>
                        <div class="modal-footer">
                            {/* <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}
                            <button type="button" class="btn btn-primary" data-bs-dismiss="modal" onClick={SettingSetup}>Save changes</button>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />

        </div>
    )
}

export default Dashboard
