import React, { useState } from 'react'
import { useUser } from '../../Authdata'
function Purchase_invoice_three({ url, bosNo, datelocal, billing, alldataIndireact_direact, discount, total, wordstotal, debtarname, totalamount, deleteIndex, BillControler, invoicdesc, invoiceAlignmentmeng }) {
    const { auth } = useUser();
    const [deleteIndexno, setDeleteIndexNo] = useState("");
    deleteIndex(deleteIndexno);
    return (
        <div>
            <div className="container-fluid ">
                <div className="row mt-5">
                    <div className="col-md-5 border border-dark  ">
                        <div className='row  p-2 text-start'>
                            {/* <img src={`${url}/upload/${auth.logo}`} alt={`${auth.logo}`} height={"100px"} width={"10px"}/> */}
                            <p><b>{auth.frm_name}</b><br />{auth.address}<br />Sunnyvale, CA 94085</p>
                        </div>

                        <div className='row border-top border-dark p-2 text-start'>
                            Buyer:
                            <p><b>{BillControler == "cash" ? "Cash" : debtarname.name}</b>
                                <br />{BillControler != "cash" ? debtarname.address || debtarname.account : null}<br />{BillControler != "cash" ? debtarname.phoneno || debtarname.ifsc : null}
                            </p>
                        </div>
                    </div>
                    <div className="col-md-7 border border-dark">
                        <div className="row ">
                            <table className="table  w-100 m-0" cellSpacing="0" cellPadding="10">
                                <tbody>
                                    <tr>
                                        <td className="border-bottom border-dark w-50 text-start">
                                            <b className='d-block'>Invoice No</b>
                                            <span>{bosNo}</span>
                                        </td>
                                        <td className="border-start border-dark w-50 text-start">
                                            <b className='d-block'>Dated</b>
                                            <span>{datelocal}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='border-bottom border-dark w-50 text-start'>
                                            <b className='d-block'>Delivery Note</b>
                                            <span></span>
                                        </td>
                                        <td className='border-start  border-dark w-50 text-start'>
                                            <b className='d-block'>Mode/Terms of Payment</b>
                                            <span>{BillControler === "cash" ? "Cash" : "Credit"}</span>
                                        </td>
                                    </tr>
                                    {/* <tr>
                                    <td className="border-bottom border-bottom border-dark w-50">
                                        <b>Invoice No</b>
                                        <span></span>
                                    </td>
                                    <td className="border-start border-bottom border-dark w-50">
                                        <b>Dated</b>
                                        <span></span>
                                    </td>
                                </tr> */}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="row table-responsive">
                    <table className="invoice-bill w-100">
                        <tbody>
                            <tr>
                                <td className="border border-1 border-dark">
                                    <b>SL. No.</b>
                                </td>
                                <td className="border border-1 border-dark">
                                    <b>Particulars</b>
                                </td>
                                <td className="border border-1 border-dark">
                                    <b>Qty</b>
                                </td>

                                <td className="border border-1 border-dark">
                                    <b>Rate</b>
                                </td>
                                <td className="border border-1 border-dark">
                                    <b>Per</b>
                                </td>
                                <td className="border border-1 border-dark">
                                    <b>Amount</b>
                                </td>
                            </tr>
                            <tr>
                                <td className='border border-end border-dark'>
                                    {billing && billing.map((obj, index) =>
                                        <p key={index}>{++index}</p>
                                    )}
                                    {
                                        alldataIndireact_direact && alldataIndireact_direact.map((obj, index) =>
                                            <p>&nbsp;</p>
                                        )
                                    }
                                </td>
                                <td>
                                    <div className="particular-items">
                                        {billing && billing.map((obj) =>
                                            <p key={obj.id}>{obj.particular}</p>
                                        )}
                                        {
                                            alldataIndireact_direact && alldataIndireact_direact.map((obj) =>
                                                <p className='text-end'>{obj.particular}</p>
                                            )
                                        }
                                    </div>
                                </td>
                                <td className='border border-end border-dark'>
                                    <div className="particular-items amount-items">
                                        {billing && billing.map((obj) =>
                                            <p key={obj.id}>{obj.quaty}</p>
                                        )}

                                    </div>
                                    {
                                        alldataIndireact_direact && alldataIndireact_direact.map((obj, index) =>
                                            <p>&nbsp;</p>
                                        )
                                    }
                                </td>

                                <td className='border border-end border-dark'>
                                    <div className="particular-items amount-items">
                                        {billing && billing.map((obj) =>
                                            <p key={obj.id}>{obj.rate}</p>
                                        )}
                                        {
                                            alldataIndireact_direact && alldataIndireact_direact.map((item) =>
                                                <p className='text-end'>
                                                    {item.percentage != "" ? `${item.percentage}%` : null}

                                                </p>
                                            )
                                        }
                                    </div>
                                </td>
                                <td className='border border-end border-dark'>
                                    <div className="particular-items amount-items">
                                        {billing && billing.map((obj) =>
                                            <p key={obj.unit}>{obj.unit ? obj.unit : <span>&nbsp;</span>}</p>
                                        )}

                                    </div>
                                    {
                                        alldataIndireact_direact && alldataIndireact_direact.map((obj, index) =>
                                            <p>&nbsp;</p>
                                        )
                                    }
                                </td>
                                <td className='border border-end border-dark'>
                                    <div className="particular-items amount-items">
                                        {billing && billing.map((obj, index) =>
                                            <p key={obj.id}>&#8377;{obj.Amout} <span><button className='btn-close hide-print' onClick={() => setDeleteIndexNo({ stock_id: obj.stockid ? obj.stockid : null, pro_id: obj.pro_id, index, item_id: obj.item_id })}></button></span></p>

                                        )}
                                        {
                                            alldataIndireact_direact && alldataIndireact_direact.map((item) =>
                                                <p>&#8377;{item.percentage != "" ? discount : item.amount} </p>
                                            )
                                        }

                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className='border border-1 border-dark'></td>
                                <td className='border border-1 border-dark'><b>Total</b></td>
                                <td className='border border-1 border-dark'><b></b></td>
                                <td className='border border-1 border-dark'><b></b></td>
                                <td className='border border-1 border-dark'><b></b></td>
                                <td className='border border-1 border-dark'>&#8377;{totalamount != 0 ? totalamount : total}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='row border border-dark text-start'>
                    <div className="col-12">
                        <div>Amount chargeable (in words)</div>
                        <b>Rupess &nbsp;{wordstotal}</b>
                        {/* <p className="mt-50">Entry Id: <b>CORP 3793457</b></p>
                    <p>In case you wish to pay us through ACH / Wire Transfer.<br /> Refer our bank details below</p>
                    <p className="bank-details">
                        <span>Bank Name: XYZ Bank</span><br />
                        <span>Beneficiary Name: XYZ Bank</span><br />
                        <span>A/C No: 6563563466</span><br />
                        <span>ABA/Routing No: 23232323</span><br />
                        <span>Swift Code: 4545</span><br />
                    </p> */}
                    </div>
                    <div className='col-md-6 mt-5'>
                        <div className={`d-${invoiceAlignmentmeng.show_desc}`}>
                            <h6 className='text-decoration-underline'>Description</h6>
                            {invoicdesc}
                        </div>
                    </div>
                    <div className="col-md-6 text-end mt-5">
                        <div className="">
                            <p>For {auth.frm_name}</p>
                            <p>Authorised Signature</p>
                        </div>
                    </div>
                </div>

            </div >
        </div>
    )
}

export default Purchase_invoice_three