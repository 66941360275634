import axios from 'axios';
import React, { useEffect,useState } from 'react'
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ToWords } from 'to-words';
import LedgerPop from './LedgerPop';
import Direact_and_Indireact from './Direact_and_Indireact';
import AddPro from './AddPro';
import { useUser } from '../Authdata';
function Invoiceaddpanel({ adbilldata, url, localdate, vocheri, settingcontrol, billNo, newbill, debitornamesetup, voucherupdate, Indireact_and_direact, setupledcranddr, billcontroler, billSaleset, returnBillno, deletebillItem, deleteItem }) {
    // all states defind 
    const { auth } = useUser();
    const [vocherdata, setVocherData] = useState(null);
    const [setting, setSetting] = useState(JSON.parse(localStorage.getItem("setting")));
    const [reloadpage, setReloadpage] = useState(0);
    const [data, setData] = useState([]);
    const [invoicedata, setInvoiceData] = useState([]);
    const [bosno, setBosNo] = useState("");
    // const [debNo, setDebNo] = useState("");
    // const [creNo, setCreNo] = useState("");
    const [billofsplly, setBillofSplly] = useState({});
    const [particular, setParticular] = useState("");
    const [rate, setRate] = useState(0);
    const [qty, setQty] = useState([]);
    const [quaty, setQuaty] = useState("");
    const [Amout, setAmoutes] = useState(0);
    const [Discount, setDiscount] = useState(0);
    const [billing, setBilling] = useState([]);
    const [indireact_and_direct_data, setIndireact_and_direct_data] = useState([]);
    const [BillControler, setBillControler] = useState("cash");
    const [debtarname, SetDebtarname] = useState(null);
    const [debtorname, setDebtorName] = useState([]);
    const [purchasename, setPurchaseName] = useState([]);
    const [salename, setSaleName] = useState([]);
    const redirect = useNavigate();
    const [datelocal, setDatelocal] = useState("");
    const [stock, setStock] = useState([]);
    const [purchaseandsale, setPurchaseandsale] = useState("");
    const [unit, setUnit] = useState("");
    const params = useParams();
    //this useEffect use Invoice data fetch and set state
    useEffect(() => {
        const InvoiceApiCall = async (table) => {
            try {
                let res3 = await axios.get(`${url}/${table}/${auth.id}_s_p_cn_dn`);
                setInvoiceData(res3.data);
            } catch (error) {
                console.error('Error fetching invoice data:', error);
            }
        };

        let table = "";
        if (vocheri === "sale") {
            table = "sale";
        } else if (vocheri === "purchase") {
            table = "purchase";
        } else if (vocheri === "debitnote") {
            table = "debit-note";
        } else if (vocheri === "creditnote") {
            table = "credit-note";
        }

        if (vocheri !== "receipt" && vocheri !== "payment" && table) {
            InvoiceApiCall(table);
        }
    }, [vocheri, url, auth, setInvoiceData]);
    useEffect(() => {
        if (setting.bosno !== "manual" && !params.slug) {  // Check for params.slug inside the useEffect
            const InoviceNumberAutomate = async (table) => {
                let res3;
                try {
                    res3 = await axios.get(`${url}/${table}/${auth.id}_s_p_cn_dn`);
                } catch (error) {
                    console.error("Error fetching invoice data:", error);
                    return;
                }
                let maxInvoiceNo = 0;
                if (res3 && res3.data) {
                    res3.data.forEach((inv) => {
                        if (table === "sale" || table === "purchase") {
                            maxInvoiceNo = Math.max(maxInvoiceNo, inv.bos_no || 0);
                        } else if (table === "debit-note") {
                            maxInvoiceNo = Math.max(maxInvoiceNo, inv.debit_no || 0);
                        } else if (table === "credit-note") {
                            maxInvoiceNo = Math.max(maxInvoiceNo, inv.credit_no || 0);
                        }
                    });
                }
                if (table === "sale" || table === "purchase") {
                    billNo && billNo({ bosno: maxInvoiceNo + 1 });
                } else if (table === "debit-note") {
                    billNo && billNo({ debno: maxInvoiceNo + 1 });
                } else if (table === "credit-note") {
                    billNo && billNo({ creno: maxInvoiceNo + 1 });
                }
            };

            let table = "";
            if (vocheri === "sale") {
                table = "sale";
            } else if (vocheri === "purchase") {
                table = "purchase";
            } else if (vocheri === "debitnote") {
                table = "debit-note";
            } else if (vocheri === "creditnote") {
                table = "credit-note";
            }

            if (vocheri !== "receipt" && vocheri !== "payment" && table) {
                InoviceNumberAutomate(table);
            }
        }
    }, [newbill, setting.bosno, params.slug]);  // Add params.slug as a dependency


    //this useEffect setting set
    returnBillno && returnBillno(bosno)
    const ManualInoviceNumber = (val) => {
        if (setting.bosno != "automatic") {
            if (vocheri == "sale" || vocheri == "purchase") {
                billNo && billNo({ bosno: val });
            } else if (vocheri == "debitnote") {
                billNo && billNo({ debno: val })
            } else if (vocheri == "creditnote") {
                billNo && billNo({ creno: val })
            }
        }
    }
    useEffect(() => {
        setSetting(JSON.parse(localStorage.getItem('setting')))
    }, [settingcontrol]);
    const [error, setError] = useState({
        bosNo: "", creNo: "", debNo: "",
        date: "",
        particular: "",
        quaty: "",
        purchaseandsale: "",
        creditanddebitbosno: ""
    })

    billcontroler && billcontroler(BillControler)
    var date = "";
    let table = "";
    let apitable = "";
    if (vocheri == "sale") {
        table = "sale"
        apitable = "s_p_cn_dn";
    } else if (vocheri == "purchase") {
        table = "purchase"
        apitable = "s_p_cn_dn";
    } else if (vocheri == "debitnote") {
        table = "debitnote"
        apitable = "s_p_cn_dn";
    } else if (vocheri == "creditnote") {
        table = "creditnote"
        apitable = "s_p_cn_dn";
    } else if (vocheri == "receipt") {
        table = "payment_in"
        apitable = "pi_po";
    } else if (vocheri == "payment") {
        table = "payment_out"
        apitable = "pi_po";
    }
    //this useEffect use get product and ledger 
    useEffect(() => {
        const fetchData = async () => {
            if (params && params.slug) {
                try {
                    const res1 = await axios.get(`${url}/viewdata/${params.slug}/${auth.id}_${apitable}/${auth.id}_stock/${table}`);
                    if (res1 && res1.data) {
                        if (vocheri === "sale" || vocheri === "purchase") {
                            billNo && billNo({ bosno: res1.data.bos_no });
                        } else if (vocheri === "creditnote") {
                            billNo && billNo({ creno: res1.data.credit_no });
                        } else if (vocheri === "debitnote") {
                            billNo && billNo({ debno: res1.data.debit_no });
                        }
                        const responsLedgerCr = await axios.get(`${url}/show_ledger/${auth.id}_ledger/${res1.data.ledger_cr}`);
                        const responsLedgerDr = await axios.get(`${url}/show_ledger/${auth.id}_ledger/${res1.data.ledger_dr}`);

                        const ledgerCrdata = responsLedgerCr.data;
                        const ledgerDrdata = responsLedgerDr.data;

                        let billcontrol;

                        if (table === "sale" || table === "debitnote") {
                            billcontrol = ledgerDrdata;
                        } else if (table === "purchase" || table === "creditnote") {
                            billcontrol = ledgerCrdata;
                        } else {
                            billcontrol = null;
                        }
                        if (billcontrol && billcontrol.name === "Cash") {
                            setBillControler("cash");
                            debitornamesetup(null);
                        } else if (table == "purchase" || table == "creditnote") {
                            setBillControler("debtor");
                            let debtor = "";

                            if (ledgerCrdata.under === "creditor" || ledgerCrdata.under === "debtor") {
                                debtor = JSON.stringify({
                                    id: ledgerCrdata.id,
                                    name: ledgerCrdata.name,
                                    address: ledgerCrdata.address,
                                    phoneno: ledgerCrdata.phoneno
                                });
                            } else if (ledgerCrdata.under === "bank") {
                                debtor = JSON.stringify({
                                    id: ledgerCrdata.id,
                                    name: ledgerCrdata.name,
                                    account: ledgerCrdata.account_no,
                                    ifsc: ledgerCrdata.ifsc
                                });
                            } else {
                                debtor = JSON.stringify({
                                    id: ledgerCrdata.id,
                                    name: ledgerCrdata.name
                                });
                            }

                            debitornamesetup(debtor);
                            setupledcranddr(JSON.stringify({ id: ledgerDrdata.id, name: ledgerDrdata.name }));

                        } else if (table == "sale" || table == "debitnote") {
                            setBillControler("debtor");
                            let debtor = "";

                            if (ledgerDrdata.under === "creditor" || ledgerDrdata.under === "debtor") {
                                debtor = JSON.stringify({
                                    id: ledgerDrdata.id,
                                    name: ledgerDrdata.name,
                                    address: ledgerDrdata.address,
                                    phoneno: ledgerDrdata.phoneno
                                });
                            } else if (ledgerDrdata.under === "bank") {
                                debtor = JSON.stringify({
                                    id: ledgerDrdata.id,
                                    name: ledgerDrdata.name,
                                    account: ledgerDrdata.account_no,
                                    ifsc: ledgerDrdata.ifsc
                                });
                            } else {
                                debtor = JSON.stringify({
                                    id: ledgerDrdata.id,
                                    name: ledgerDrdata.name
                                });
                            }

                            debitornamesetup(debtor);
                            setupledcranddr(JSON.stringify({ id: ledgerCrdata.id, name: ledgerCrdata.name }));

                        }
                    }
                    setDatelocal(res1.data.date);
                    let stockid = JSON.parse(res1.data.stock_ids);
                    const bill = JSON.parse(res1.data.bills);
                    const iAndd = JSON.parse(bill.indireact_and_direact);
                    let billdata = JSON.parse(bill.bill);
                    billdata = billdata.map((item, index) => ({
                        ...item,
                        stockid: stockid[index]
                    }));
                    Indireact_and_direact && Indireact_and_direact(iAndd);
                    setBilling(billdata);
                    voucherupdate(res1.data);

                } catch (error) {
                    console.error('Error fetching viewdata:', error);
                }
            } else {
                redirect("/dashboard");
            }

            try {
                const res2 = await axios.get(`${url}/show_products/${auth.id}_product/${auth.id}_stock`);
                const arr = res2 && res2.data.filter(d => d.voucher === "opening-balance");
                setData(arr);
            } catch (error) {
                console.error('Error fetching pro-stock data:', error);
            }
            try {
                const res4 = await axios.get(`${url}/show_ledgers/${auth.id}_ledger`);
                const ledgerData = res4.data;
                let filteredData = ledgerData.filter(obj => ['cash', 'bank', 'debtor', 'creditor'].includes(obj.under));
                filteredData = filteredData.filter(obj => obj.name !== "Cash");
                const purchase = ledgerData.filter(obj => obj.under === "purchase");
                const sale = ledgerData.filter(obj => obj.under === "sale");
                setDebtorName(filteredData);
                setPurchaseName(purchase);
                setSaleName(sale);
            } catch (error) {
                console.error('Error fetching ledger data:', error);
            }

            try {
                const res5 = await axios.get(`${url}/show_products/${auth.id}_product/${auth.id}_stock`);
                if (res5 && res5.data) {
                    setStock(res5.data);
                }
            } catch (error) {
                console.error('Error fetching stock data:', error);
            }
        };

        fetchData();
    }, [auth.id, params.slug, vocheri]);
    if (!datelocal.includes('/') && datelocal != "") {
        var date = new Date(datelocal);
        var dd = String(date.getDate()).padStart(2, '0');
        var mm = String(date.getMonth() + 1).padStart(2, '0');
        var yyyy = date.getFullYear();
        date = dd + '/' + mm + '/' + yyyy;

    } else if (datelocal.includes('/')) {
        date = datelocal;
    }
    else {
        var date = new Date();
        var dd = String(date.getDate()).padStart(2, '0');
        var mm = String(date.getMonth() + 1).padStart(2, '0');
        var yyyy = date.getFullYear();
        date = dd + '/' + mm + '/' + yyyy;
    }
    localdate && localdate(date)
    //this code is useing ledger setup
    const LedgerData = async () => {
        // axios.get(`${url}/ledger/${auth.id}`).then((res) => {a
        const res = await axios.get(`${url}/show_ledgers/${auth.id}_ledger`);
        let data = [];
        let purchase = [];
        let sale = [];
        res.data.forEach(obj => {
            if (obj.under == 'cash' && obj.name != 'Cash' || obj.under == 'bank' || obj.under == 'debtor' || obj.under == "creditor") {
                data.push(obj)
            }
            if (obj.under == "purchase") {
                purchase.push(obj);
            } else if (obj.under == "sale") {
                sale.push(obj);
            }
        });
        setDebtorName(data)
        setPurchaseName(purchase)
        setSaleName(sale)
        // })

    }
    //this useEffect use all states reset;
    useEffect(() => {
        setParticular("");
        setRate(0)
        setQuaty("")
        setQty([]);
        setBosNo("");
        // setCreNo("");
        // setDebNo("");
        setAmoutes(0);
        setBilling([]);
        SetDebtarname(null)
    }, [newbill])
    //this code is using select product then set product rate and quataty and unit
    const SetParRate = (v) => {
        setParticular(v)
        data && data.forEach((el) => {
            if (el.name == v) {
                setRate(el.sale_price);
                let Qty = [];
                for (let index = 1; index <= 15; index++) {
                    Qty.push(index)
                }
                setQty(Qty)
                setUnit(el.unit)
            }
        })

    }
    //this code is using set rate and amount according to quatity
    const SetRateItem = (v) => {
        setRate(v?v:0);
        let amout = v * quaty;
        setAmoutes(amout)
    }
    //this code is using  discount set
    const Setupdiscount = (v) => {
        setDiscount(v);
        let descount = (v * 100) / Amout
        let amout = Math.round(Amout - descount);
        // let amout = (100 * v) / Amout;
        // console.log(amout);
        setAmoutes(amout)
    }
    //this code is using set quatity then check product quatity is negative then warnign show other wise normaly run
    const SetAmount = (q) => {
        let openingbal = 0;
        if (vocheri === "sale" || vocheri === "debitnote") {
            stock && stock.forEach((dt) => {
                if (dt.name === particular && dt.voucher === "opening-balance") {
                    openingbal = dt.quaty;
                } else if ((dt.name === particular && dt.voucher === "sale") ||
                    (dt.name === particular && dt.voucher === "debitnote") ||
                    (dt.name === particular && dt.voucher === "input")) {
                    openingbal -= dt.quaty;
                } else if ((dt.name === particular && dt.voucher === "purchase") ||
                    (dt.name === particular && dt.voucher === "creditnote") ||
                    (dt.name === particular && dt.voucher === "output")) {
                    openingbal += dt.quaty;
                }
            });

            if (q > openingbal && particular) {
                toast.warn(`Negative Stock ${openingbal - parseInt(q)}`);
            }
            setQuaty(q);
            let amout = rate * q;
            setAmoutes(amout);
        } else {
            setQuaty(q);
            let amout = rate * q;
            setAmoutes(amout);
        }

    };
    //this code is using adding bill data  inside invoice
    const AddBille = () => {
        let validations = {};
        let setupManual = setting.bosno === "manual";
        let localDate = new Date(datelocal);
        let authDate = new Date(auth.date);
        const validateInvoiceNumber = (invoiceField, invoiceValue, errorMessage) => {
            let invoiceExists = invoicedata.some(invoice => invoice[invoiceField] == invoiceValue);
            if (!invoiceValue.trim()) {
                validations[invoiceField] = "Invoice No is required";
            } else if (invoiceExists) {
                validations[invoiceField] = errorMessage;
            }
        };
        if (vocheri !== "receipt" && vocheri !== "payment" && vocheri !== "journal") {
            if (setupManual) {
                if (vocheri === "sale" || vocheri === "purchase") {
                    validateInvoiceNumber('bos_no', bosNo, "Invoice No already exists in table!");
                } else if (vocheri === "creditnote") {
                    validateInvoiceNumber('creNo', creNo, "Invoice No already exists in table!");
                } else if (vocheri === "debitnote") {
                    validateInvoiceNumber('debNo', debNo, "Invoice No already exists in table!");
                }
            }
            if (localDate < authDate) {
                validations.date = "Transaction date can't be prior to Opening Date";
            }
            if (!particular.trim()) {
                validations.particular = "Particular is required";
            }
            if (!quaty.trim()) {
                validations.quaty = "Quantity is required";
            }
            if (purchaseandsale == null) {
                validations.purchaseandsale = "Ledger is required";
            }

        }

        setError(validations);
        if (Object.keys(validations).length === 0) {
            setReloadpage(reloadpage + 1);

            let proId = data.find(val => val.name === particular)?.id || 0;
            let uniqueItemId = `item_${proId}_${new Date().getTime()}`;
            let newBillEntry = { particular, rate, quaty, unit, Amout, pro_id: proId, item_id: uniqueItemId };
            setBilling([...billing, newBillEntry]);
            // setData([]);
            setParticular("");
            setRate(0);
            setQuaty("");
            setUnit("");
            setAmoutes(0);
            // setBosNo("");
        }
    };

    //this code is using delete data inside invoice
    const DeleteBille = () => {
        if (deletebillItem) {
            let billdata = billing.filter((item, index) => index == deletebillItem ? null : item)
            setBilling(billdata);
            deleteItem("");
        }
    }
    const deleteIteminvoice = (key) => {
        let billdata = billing.filter((item, index) => {
            // if (item.pro_id == key.pro_id && index==key.index) {
            if (item.item_id == key.item_id) {
                return false
            } else {
                return true
            }
        });
        setBilling(billdata)
        deleteItem(null)
    }
    const deleteItemStock = async (key) => {
        try {
            // Filter out the item to be deleted
            const billdata = billing.filter((item) => item.item_id !== key.item_id);
            // Update state with the filtered data
            setBilling(billdata);

            // Perform the delete operation
            await axios.delete(`${url}/deletestock_item/${auth.id}_stock/${key.stock_id}`);
            toast.success("Delete Item Successfully");
            // Call deleteItem function if necessary
            deleteItem(null);
        } catch (error) {
            console.error("Error deleting item from stock:", error);
            // You can add further error handling here if needed, like showing an alert to the user
        }
    };
    useEffect(() => {
        if (deletebillItem && deletebillItem.stock_id == null) {
            deleteIteminvoice(deletebillItem);
        } else if (deletebillItem && deletebillItem.stock_id) {
            deleteItemStock(deletebillItem);
        }
    }, [deletebillItem]);

    //this code use date is not requiere validation
    const dateisnotreq = () => {
        var date = new Date(auth.date);
        var dd = String(date.getDate()).padStart(2, '0');
        var mm = String(date.getMonth() + 1).padStart(2, '0');
        var yyyy = date.getFullYear();
        return yyyy + '-' + mm + '-' + dd;
    }
    // this code charges like discode then call this function
    const Charges = (obj) => {
        Indireact_and_direact && Indireact_and_direact(obj)
    }
    //this code is using new product add
    const addnewparticulars = (data) => {
        setData(data)
    }
    adbilldata && adbilldata(billing);
    billSaleset && billSaleset(purchaseandsale);

    // setupledcranddr && setupledcranddr(purchaseandsale);

    const debtornameset = (val) => {
        if (BillControler != "cash") {
            debitornamesetup && debitornamesetup(val);
        } else {
            debitornamesetup && debitornamesetup({});
        }
    }
    const PurchaseAndSale = (val) => {
        setPurchaseandsale(val);
        setupledcranddr && setupledcranddr(val);
    }
    return (
        <div>
            {
                vocheri == "receipt" || vocheri == "payment" || vocheri == "journal" ? null : <>
                    <div className='mb-3'><h6>Bill Managment</h6></div>
                    <hr></hr>
                </>
            }
            {
                vocheri == "receipt" || vocheri == "payment" || vocheri == "journal" ? null :
                    vocheri !== "stock-journal" ? <div className='mb-3'>
                        <div className='m-2 d-inline'><Link onClick={() => setBillControler("cash")} id='cash'>Cash</Link></div>
                        <div className='m-2 d-inline'><Link onClick={() => setBillControler("debtor")} id='credit'>Credit</Link></div>
                    </div> : null
            }

            {
                vocheri == "receipt" || vocheri == "payment" || vocheri == "journal" ? null :
                    BillControler == "debtor" ? <>
                        <div className='mb-3'>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Suppliers	</label>
                            <select onChange={(e) => debtornameset(e.target.value)} className='form-control'>
                                {vocherdata && vocherdata.ledger_dr != 'cash' ? <option value="">{debtarname}</option> : <option value="">--Select--</option>}
                                {
                                    debtorname && debtorname.map((debdata) => <>
                                        <option value={debdata.under == "creditor" || debdata.under == "debtor" ? JSON.stringify({ id: debdata.id, name: debdata.name, address: debdata.address, phoneno: debdata.phoneno }) : debdata.under == "bank" ? JSON.stringify({ id: debdata.id, name: debdata.name, account: debdata.account_no, ifsc: debdata.ifsc }) : JSON.stringify({ id: debdata.id, name: debdata.name })}>{debdata.name}</option>
                                    </>
                                    )
                                }
                            </select>
                            <div className='d-flex flex-row-reverse'>
                                <button className='btn btn-link' data-bs-toggle="modal" data-bs-target="#debtoradd">+Client</button>
                            </div>
                        </div>
                    </> : null
            }

            <div>
                {
                    vocheri == "receipt" || vocheri == "payment" || vocheri == "journal" ? null :
                        setting.bosno == "manual" ?
                            vocheri == "sale" || vocheri == "purchase" ? <div class="mb-3">
                                <label class="form-label">Bill of Supply</label>
                                <input type='text' className='form-control' onChange={(e) => ManualInoviceNumber(e.target.value)}  ></input>
                                <div>{error.bos_no && <span className='text-danger'>{error.bos_no}</span>}</div>
                            </div>
                                : vocheri == "creditnote" ? <div class="mb-3">
                                    <label class="form-label">Bill of Supply</label>
                                    <input type='text' className='form-control' onChange={(e) => ManualInoviceNumber(e.target.value)} ></input>
                                    <div>{error.creNo && <span className='text-danger'>{error.creNo}</span>}</div>
                                </div>
                                    : vocheri == "debitnote" ? <div class="mb-3">
                                        <label class="form-label">Bill of Supply</label>
                                        <input type='text' className='form-control' onChange={(e) => ManualInoviceNumber(e.target.value)} ></input>
                                        <div>{error.debNo && <span className='text-danger'>{error.debNo}</span>}</div>
                                    </div>
                                        : null : null
                }
                {
                    vocheri == "receipt" || vocheri == "payment" || vocheri == "journal" ? null :
                        vocheri == "purchase" || vocheri == "debitnote" ?
                            <div class="mb-3">
                                <label class="form-label">Purchase Ledger</label>
                                {/* <select onChange={(e) => setPurchaseandsale(e.target.value)} className='form-control'> */}
                                <select onChange={(e) => PurchaseAndSale(e.target.value)} className='form-control'>
                                    <option value="">--Select--</option>
                                    {
                                        purchasename && purchasename.map((pur) =>
                                            <>
                                                <option value={JSON.stringify({ id: pur.id, name: pur.name })}>{pur.name}</option>
                                            </>
                                        )

                                    }
                                </select>
                                <div>{error.purchaseandsale && <span className='text-danger'>{error.purchaseandsale}</span>}</div>
                            </div>
                            : vocheri == "sale" || vocheri == "creditnote" ?
                                <div class="mb-3">
                                    <label class="form-label">Sale Ledger</label>
                                    <select onChange={(e) => PurchaseAndSale(e.target.value)} className='form-control'>
                                        <option value="">--Select--</option>
                                        {
                                            salename && salename.map((sal) =>
                                                <>
                                                    <option value={JSON.stringify({ id: sal.id, name: sal.name })}>{sal.name}</option>
                                                </>
                                            )
                                        }
                                    </select>
                                    <div>{error.purchaseandsale && <span className='text-danger'>{error.purchaseandsale}</span>}</div>
                                </div>
                                : null
                }
                {
                    vocheri == "receipt" || vocheri == "payment" || vocheri == "creditnote" || vocheri == "debitnote" || vocheri == "journal" ? null : <>
                        <div class="mb-3">
                           
                            <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#direct-and-indirect">
                            <i class="bi bi-cash-coin"></i> Charges
                            </button>
                        </div>
                    </>

                }
                {
                    vocheri == "receipt" || vocheri == "payment" || vocheri == "journal" ? null :
                        <div class="mb-3">
                            <label class="form-label">Date</label>
                            <input type='date' className='form-control' dateFormat onChange={(e) => setDatelocal(e.target.value)} min={dateisnotreq()}></input>
                            <div>{error.date && <span className='text-danger'>{error.date}</span>}</div>
                        </div>
                }
                <div className="mb-3">
                    <label class="form-label">Particulars</label>
                    <select onChange={(e) => SetParRate(e.target.value)} className='form-control' value={particular}>
                        <option value="">--Select--</option>
                        {
                            data.map((par) => <>
                                <option value={par.name}>{par.name}</option>
                            </>
                            )
                        }
                    </select>
                    <div>{error.particular && <span className='text-danger'>{error.particular}</span>}</div>
                    {vocheri == "receipt" || vocheri == "payment" || vocheri == "creditnote" || vocheri == "debitnote" || vocheri == "journal" ? null : <div className='text-end'><AddPro url={url} onupdatepro={addnewparticulars} /></div>}
                </div>
                <div class="mb-3">
                    <label class="form-label">Quantity</label>
                    {/* <select onChange={(e) => SetAmount(e.target.value)} className='form-control'>
                        <option value="">--Select--</option>
                        {
                            qty.map((qty) =><option value={qty}>{qty}</option>)
                        }
                        <option value="custom">Custom</option>
                    </select> */}
                    <input type="number" class="form-control" onChange={(e) => SetAmount(e.target.value)} value={quaty}></input>
                    <div>{error.quaty && <span className='text-danger'>{error.quaty}</span>}</div>
                </div>
                <div class="mb-3">
                    <label class="form-label">Unit</label>
                    <input type='text' className='form-control' disabled value={unit}></input>
                </div>
                {
                    vocheri == "creditnote" || vocheri == "debitnote" ?
                        <div class="mb-3">
                            <label for="exampleInputPassword1" class="form-label">BOSNO:</label>
                            <input type='number' className='form-control' value={bosno} onChange={(e) => setBosNo(e.target.value)}></input>
                            <div>{error.bosNo && <span className='text-danger'>{error.bosNo}</span>}</div>
                        </div>
                        : null
                }

                <div class="mb-3">
                    <label for="exampleInputPassword1" class="form-label">Rate</label>
                    <input type="number" className='form-control' value={rate} defaultValue={rate} onChange={(e) => SetRateItem(e.target.value)}></input>
                </div>
                {/* {
                    setting.discount == "yes" ? <div class="mb-3">
                        <label for="exampleInputPassword1" class="form-label">Discount %</label>
                        <input className='form-control' value={Discount} onChange={(e) => Setupdiscount(parseInt(e.target.value))}></input>
                    </div> : false
                } */}

                <div class="mb-3">
                    <label for="exampleInputPassword1" class="form-label">Amount</label>
                    <input className='form-control' value={Amout} disabled></input>
                </div>
            </div>


            <div class="d-grid gap-2 col-12 mx-auto">
                <button class="btn btn-primary" onClick={AddBille}><i class="bi bi-journal-plus"></i>Add</button>&nbsp;
            </div>
            {/* <button class="btn btn-danger" onClick={DeleteBille}>Delete</button> */}
            {/* </div> */}
            {
                vocheri == "receipt" || vocheri == "payment" || vocheri == "journal" ? null :
                    <div class="modal fade" id="debtoradd" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <LedgerPop url={url} onChildStateChange={LedgerData} />
                    </div>
            }

            {
                vocheri == "receipt" || vocheri == "payment" || vocheri == "journal" ? null :
                    <div class="modal fade" id="direct-and-indirect" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <Direact_and_Indireact url={url} onbilldata={Charges} slug={params.slug} table={table}></Direact_and_Indireact>
                    </div>
            }
          
        </div>

    )
}

export default Invoiceaddpanel