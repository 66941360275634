import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
// import { authdata } from '../Authdata';
import { useUser } from '../Authdata';
function AddPro({ url, onupdatepro }) {
    const { auth } = useUser();
    const [name, setName] = useState("");
    const [sale_price, setSalePrice] = useState(0);
    const [purchase_price, setPurchasePrice] = useState(0);
    const [quantity, setQuantity] = useState(0);
    const [data, setData] = useState([]);
    const [editdata, setEditdata] = useState([]);
    const [staterecall, setStateRecall] = useState(0);
    const [ViewData, setViewData] = useState([]);
    const [unit, setUnit] = useState("");
    const [unitdata, setUnitData] = useState([
        { sym: "BAG", name: "BAGS" },
        { sym: "BAL", name: "BALE" },
        { sym: "BDL", name: "BUNDLES" },
        { sym: "BKL", name: "BUCKLES" },
        { sym: "BOU", name: "BILLIONS OF UNITS" },
        { sym: "BOX", name: "BOX" },
        { sym: "BTL", name: "BOTTLES" },
        { sym: "BUN", name: "BUNCHES" },
        { sym: "CAN", name: "CANS" },
        { sym: "CBM", name: "CUBIC METER" },
        { sym: "CCM", name: "CUBIC CENTIMETER" },
        { sym: "CMS", name: "CENTIMETER" },
        { sym: "CTN", name: "CARTONS" },
        { sym: "DOZ", name: "DOZEN" },
        { sym: "DRM", name: "DRUM" },
        { sym: "GGR", name: "GREAT GROSS" },
        { sym: "GMS", name: "GRAMS" },
        { sym: "GRS", name: "GROSS" },
        { sym: "GYD", name: "GROSS YARDS" },
        { sym: "KGS", name: "KILOGRAMS" },
        { sym: "KLR", name: "KILOLITRE" },
        { sym: "KME", name: "KILOMETRE" },
        { sym: "MLT", name: "MILLILITRE" },
        { sym: "MTR", name: "METERS" },
        { sym: "MTS", name: "METRIC TON" },
        { sym: "NOS", name: "NUMBERS" },
        { sym: "PAC", name: "PACKS" },
        { sym: "PCS", name: "PIECES" },
        { sym: "PRS", name: "PAIRS" },
        { sym: "QTL", name: "QUINTAL" },
        { sym: "ROL", name: "ROLLS" },
        { sym: "SET", name: "SETS" },
        { sym: "SQF", name: "SQUARE FEET" },
        { sym: "SQM", name: "SQUARE METERS" },
        { sym: "SQY", name: "SQUARE YARDS" },
        { sym: "TBS", name: "TABLETS" },
        { sym: "TGM", name: "TEN GRAMS" },
        { sym: "THD", name: "THOUSANDS" },
        { sym: "TON", name: "TONNES" },
        { sym: "TUB", name: "TUBES" },
        { sym: "UGS", name: "US GALLONS" },
        { sym: "UNT", name: "UNITS" },
        { sym: "YDS", name: "YARDS" },
        { sym: "OTH", name: "OTHERS" },
    ]);
    useEffect(()=>{
        const getData=async()=>{
            const response = await axios.get(`${url}/show_products/${auth.id}_product/${auth.id}_stock`);
            setData(response.data);
        }
        getData();
    },[])
    const [error, setError] = useState({
        name: "",
        quantity: "",
        unit: "",
        sale_price: "",
        purchase_price: ""
    });
    const adddata = async (e) => {
        e.preventDefault();
        
        let validation = {}
        let productexist = false;
        if (name) {
            data && data.forEach(obj => {
                console.log(obj.name);
                
                if (obj.name === name) {
                    productexist = true;
                } else {
                    productexist = false;
                }
            });
           
            
        }
        else {
            return;
        }
        if (productexist && productexist) {
            validation.name = "Product already exist";
            setError(validation)
        } else {
            let data = { name: name, sale_price, purchase_price: purchase_price, frm_id: auth.id };
            let resProduct = await axios.post(`${url}/insert_product/${auth.id}_product`, data);
            if (resProduct.data) {
                let date = new Date(auth.date);
                var dd = String(date.getDate()).padStart(2, '0');
                var mm = String(date.getMonth() + 1).padStart(2, '0');
                var yyyy = date.getFullYear();
                date = dd + '/' + mm + '/' + yyyy;
                let billdata = { date: date, particulars: resProduct.data.insertId, rate: purchase_price, quantity: quantity, total: quantity * purchase_price, frm_id: auth.id, bill_id: null, voucher: "opening-balance", unit: unit }
                let resStock = await axios.post(`${url}/insert_stock/${auth.id}_stock`, billdata);
                if (resStock.data) {
                    let res = await axios.get(`${url}/show_products/${auth.id}_product/${auth.id}_stock`)
                    if (res.data) {
                        let arr = res && res.data.filter((d) => d.voucher == "opening-balance" ? d : null)
                        onupdatepro(arr)
                        setName("");
                        setSalePrice(0);
                        setPurchasePrice(0);
                        setQuantity(0);
                        toast.success("Product Add Successfully");
                    }
                }

            }
        }

    }
    return (
        <div>
            <button type="button" class="btn btn-dark p-1 px-3 mt-1" data-bs-toggle="modal" data-bs-target="#ADDProduct" data-bs-whatever="@mdo"><i class="bi bi-cart-plus-fill"></i>Add</button>
            <div class="modal fade" id="ADDProduct" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog text-start">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Add Product</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div className='row'>

                                <div class="mb-3 col-12">
                                    <label for="recipient-name" class="col-form-label">Name:</label>
                                    <input type="text" class="form-control" id="recipient-name" onChange={(e) => setName(e.target.value)} value={name}></input>
                                    <div>{error.name ? <span className='text-danger'>{error.name}</span> : null}</div>
                                </div>
                                <div class="mb-3 col-6">
                                    <label for="message-text" class="col-form-label">Quantity (Op. Stock):</label>
                                    <input type="number" class="form-control" id="recipient-name" onChange={(e) => setQuantity(parseInt(e.target.value))} value={quantity}></input>
                                </div>
                                <div class="mb-3 col-6">
                                    <label for="message-text" class="col-form-label">Unit:</label>
                                    <select className='form-control' onChange={(e) => setUnit(e.target.value)}>
                                        <option>--Select--</option>
                                        {
                                            unitdata && unitdata.map((uqc) =>
                                                <option value={uqc.sym}>{uqc.name}</option>
                                            )
                                        }
                                    </select>
                                </div>
                                <div class="mb-3 col-12">
                                    <label for="message-text" class="col-form-label">Sale Price:</label>
                                    <input type="number" class="form-control" id="recipient-name" value={sale_price} onChange={(e) => setSalePrice(parseInt(e.target.value))}></input>
                                </div>
                                <div class="mb-3 col-12">
                                    <label for="message-text" class="col-form-label">Purchase Price:</label>
                                    <input type="number" class="form-control" id="recipient-name" value={purchase_price} onChange={(e) => setPurchasePrice(parseInt(e.target.value))}></input>
                                </div>
                            </div>

                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type='button' onClick={adddata} class="btn btn-primary" >Add</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddPro