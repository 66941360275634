import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { Link, redirect, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Profile from './Profile';
import SalesGraph from '../SalesGraph';
import { useUser } from '../Authdata';
import styles from './Dashboard.css';
import SaleRegister from '../Registers/SaleRegister';
import PurchaseRegister from '../Registers/PurchaseRegister';
import PaymentRegister from '../Registers/PaymentRegister';
import ReceiptRegister from '../Registers/ReceiptRegister';
import CreditNoteRegister from '../Registers/CreditNoteRegister';
import DebitNoteRegister from '../Registers/DebitNoteRegister';
import LedgerData from './LedgerData';
import DashboardCards from './DashboardCards/DashboardCards';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faBook, faShoppingCart, faReceipt, faMoneyCheckAlt, faCreditCard, faFileInvoiceDollar, faMoneyBillWave } from '@fortawesome/free-solid-svg-icons';
function Mainpage(props) {
    const { auth, } = useUser();
    const [cash, setCash] = useState(0);
    const [bank, setBank] = useState(0);
    const [creditor, setCreditor] = useState(0);
    const [debitor, setDebitor] = useState(0);
    const [saledata, setSaleData] = useState([]);
    const [salesData, setSalesData] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [register, setRegister] = useState("");
    const redirect = useNavigate();
    const [moveCard, setMoveCard] = useState("");
    useEffect(() => {
        APICalling();
        const fetchSalesData = async () => {
            try {
                // Fetch sales data from API endpoint
                const response = await axios.get(`${props.url}/sale/${auth.id}_s_p_cn_dn`);
                const allSales = response.data;

                // Process data to aggregate sales by day
                const aggregatedSales = {};

                // Iterate over each sale and aggregate by day
                allSales.forEach(sale => {
                    const parts = sale.date.split('/'); // Assuming the date format is 'dd-mm-yyyy'
                    const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`; // Rearrange date parts
                    const saleDate = new Date(formattedDate);
                    // const saleDate = new Date(sale.date); // Assuming 'date' is a string date representation
                    const today = new Date(); // Current date
                    const startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 29); // Date 30 days ago

                    if (saleDate >= startDate && saleDate <= today) {
                        const formattedDate = formatDate(saleDate); // Format date as 'yyyy-mm-dd'

                        if (!aggregatedSales[formattedDate]) {
                            aggregatedSales[formattedDate] = {
                                date: formattedDate,
                                total: 0,
                                count: 0
                            };
                        }

                        aggregatedSales[formattedDate].total += parseFloat(sale.total);
                        aggregatedSales[formattedDate].count++;
                    }
                });

                // Convert aggregatedSales object to an array
                const last30Days = Object.values(aggregatedSales);
                last30Days.sort((a, b) => new Date(a.date) - new Date(b.date));
                setSaleData(last30Days);
            } catch (error) {
                console.error('Error fetching sales data:', error);
            }
        };

        fetchSalesData();

    }, [auth])
    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    const Componetupdate = (val) => {
        setProfileUpdate(profileupdate + val);
    }

    const APICalling = async () => {
        let cash = 0;
        let bank = 0;
        let creditor = 0;
        let debitor = 0;

        try {
            // Fetch cash ledger data
            const cashLedgerResponse = await axios.get(`${props.url}/show_ledger_under/${auth.id}_ledger/cash`);
            const cashLedgerData = cashLedgerResponse && cashLedgerResponse.data;
            // Process each cash ledger element
            for (const element of cashLedgerData) {
                const whatDataResponse = await axios.get(`${props.url}/what-data/${element.id}/${auth.id}`);
                const whatData = whatDataResponse.data;
                // Sum total_amount for each element
                let journaldata = JSON.parse(whatData[0].Journal_data)
                let journaltotal = journaldata.Journal_credit_total - journaldata.Journal_debit_total;
                whatData.forEach(element => {
                    if (element.result_total != null) {
                        cash += parseInt(element.result_total);
                    }
                });
                cash = cash - journaltotal;
            }

            // Fetch bank ledger data
            const bankLedgerResponse = await axios.get(`${props.url}/show_ledger_under/${auth.id}_ledger/bank`);
            const bankLedgerData = bankLedgerResponse && bankLedgerResponse.data;
            // Process each bank ledger element
            for (const element of bankLedgerData) {
                const whatDataResponse = await axios.get(`${props.url}/what-data/${element.id}/${auth.id}`);
                const whatData = whatDataResponse.data;
                let journaldata = JSON.parse(whatData[0].Journal_data)
                let journaltotal = journaldata.Journal_credit_total - journaldata.Journal_debit_total;
                // Sum total_amount for each element
                whatData.forEach(element => {
                    if (element.result_total != null) {
                        bank += parseInt(element.result_total);
                    }
                });
                bank = bank - journaltotal;
            }
            const creditorLedgerResponse = await axios.get(`${props.url}/show_ledger_under/${auth.id}_ledger/creditor`);
            const creditorLedgerData = creditorLedgerResponse && creditorLedgerResponse.data;
            // Process each cash ledger element
            for (const element of creditorLedgerData) {
                const whatDataResponse = await axios.get(`${props.url}/what-data/${element.id}/${auth.id}`);
                const whatData = whatDataResponse.data;
                let journaldata = JSON.parse(whatData[0].Journal_data)
                let journaltotal = journaldata.Journal_credit_total - journaldata.Journal_debit_total;
                // Sum total_amount for each element
                whatData.forEach(element => {
                    if (element.result_total != null) {
                        creditor += parseInt(element.result_total);
                    }
                });
                creditor = creditor - journaltotal
            }
            const debitorLedgerResponse = await axios.get(`${props.url}/show_ledger_under/${auth.id}_ledger/debtor`);
            const debitorLedgerData = debitorLedgerResponse && debitorLedgerResponse.data;
            // Process each cash ledger element
            for (const element of debitorLedgerData) {
                const whatDataResponse = await axios.get(`${props.url}/what-data/${element.id}/${auth.id}`);
                const whatData = whatDataResponse.data;
                let journaldata = JSON.parse(whatData[0].Journal_data)
                let journaltotal = journaldata.Journal_credit_total - journaldata.Journal_debit_total;
                // Sum total_amount for each element
                whatData.forEach(element => {
                    if (element.result_total != null) {
                        debitor += parseInt(element.result_total);
                    }
                });
                debitor = debitor - journaltotal;
            }
            setCash(cash)
            setBank(bank)
            setCreditor(creditor)
            setDebitor(debitor)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const MoveCard = (val) => {
        // redirect(`/dashboard/${val}`)
        setMoveCard(val);
    }

    // Call the APICalling function
    return (
        <div className='container-fluid'>
            <div className='row'>
                {moveCard == "cash" || moveCard == "bank" || moveCard == "creditor" || moveCard == "debtor" ? <DashboardCards card={moveCard} url={props.url} onMoveCard={MoveCard} /> : <>


                    <div className='col-md-2'>
                        <div className='border-end border-2 text-center d-grid gap-2'>

                            {
                                register !== "" ?
                                    <Link className='btn btn-primary m-2' onClick={() => setRegister("")}>
                                        <FontAwesomeIcon icon={faHome} /> Home
                                    </Link>
                                    : null
                            }

                            <button type="button" className="btn btn-primary m-2" data-bs-toggle="modal" data-bs-target="#profileBackdrop">
                                <FontAwesomeIcon icon={faBook} /> Edit Profile
                            </button>

                            <Link className='btn btn-primary m-2' onClick={() => setRegister("ledger")}>
                                <FontAwesomeIcon icon={faBook} /> Ledger
                            </Link>

                            <Link className='btn btn-primary m-2' onClick={() => setRegister("sale-register")}>
                                <FontAwesomeIcon icon={faShoppingCart} /> Sale Register
                            </Link>

                            <Link className='btn btn-primary m-2' onClick={() => setRegister("purchase-register")}>
                                <FontAwesomeIcon icon={faReceipt} /> Purchase Register
                            </Link>

                            <Link className='btn btn-primary m-2' onClick={() => setRegister("payment-register")}>
                                <FontAwesomeIcon icon={faMoneyCheckAlt} /> Payment Register
                            </Link>

                            <Link className='btn btn-primary m-2' onClick={() => setRegister("receipt-register")}>
                                <FontAwesomeIcon icon={faMoneyBillWave} /> Receipt Register
                            </Link>

                            <Link className='btn btn-primary m-2' onClick={() => setRegister("debitnote-register")}>
                                <FontAwesomeIcon icon={faFileInvoiceDollar} /> DebitNote Register
                            </Link>

                            <Link className='btn btn-primary m-2' onClick={() => setRegister("creditnote-register")}>
                                <FontAwesomeIcon icon={faCreditCard} /> CreditNote Register
                            </Link>
                        </div>

                    </div>
                    <div className='col-md-10'>
                        <div className="row">

                            {register == "ledger" ? <LedgerData url={props.url} /> : register == "sale-register" ? <SaleRegister url={props.url} /> : register == "purchase-register" ? <PurchaseRegister url={props.url} /> : register == "payment-register" ? <PaymentRegister url={props.url} /> : register == "receipt-register" ? <ReceiptRegister url={props.url} /> : register == "debitnote-register" ? <DebitNoteRegister url={props.url} /> : register == "creditnote-register" ? <CreditNoteRegister url={props.url} /> : <>

                                <div className='col-md-5'>
                                    <div className='row'>
                                        <div className='col' onClick={() => MoveCard("cash")} style={{ cursor: "pointer" }}>
                                            <div className='card text-bg-primary mb-3 mt-3 custom-card'>
                                                <div className='card-header'><i class="bi bi-cash" style={{ fontSize: "150%" }}></i>&nbsp;CASH</div>
                                                <div className='card-body'>
                                                    <h1>&#8377;{cash}</h1>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col' onClick={() => MoveCard("bank")} style={{ cursor: "pointer" }}>
                                            <div className='card text-bg-secondary mb-3 mt-3 custom-card'>
                                                <div className='card-header'><i class="bi bi-bank" style={{ fontSize: "150%" }}></i> &nbsp;BANK</div>
                                                <div className='card-body'>

                                                    <h1>&#8377;{bank} </h1>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col' onClick={() => MoveCard("creditor")} style={{ cursor: "pointer" }}>
                                            <div className='card text-bg-primary mb-3 mt-3 custom-card'>
                                                <div className='card-header text-uppercase'><i class="bi bi-credit-card-2-back-fill"  style={{ fontSize: "150%" }}></i>&nbsp;Creditor</div>
                                                <div className='card-body'>
                                                    <h1>&#8377;{creditor}</h1>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col' onClick={() => MoveCard("debtor")} style={{ cursor: "pointer" }}>
                                            <div className='card text-bg-secondary mb-3 mt-3 custom-card'>
                                                <div className='card-header text-uppercase'><span style={{ fontSize: "150%" }}><FontAwesomeIcon icon={faFileInvoiceDollar} /></span>&nbsp;Debtor</div>
                                                <div className='card-body'>
                                                    <h1>&#8377;{debitor}</h1>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-7'>
                                    <div className='row'>
                                        <div className='col'>
                                            <div className='card text-bg-light mb-3 mt-3'>
                                                <div className='card-header'>Sales</div>
                                                <div className='card-body'>
                                                    <SalesGraph salesData={saledata} />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </>
                            }
                        </div>

                    </div>
                    {/* Main Content */}
                </>}
            </div>

            {/* Edit Profile Modal */}
            <div className="modal fade" id="profileBackdrop" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <Profile url={props.url} componentUpdate={Componetupdate} />
            </div>
            <ToastContainer/>
        </div>

    )
}

export default Mainpage