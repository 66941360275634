import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { encryptData, decryptData } from '../encryptionUtils';
import { useUser } from '../Authdata';
import LedgerPop from './LedgerPop';
import LedgerData from './LedgerData';

function Direact_and_Indireact({ url, onbilldata, slug, table }) {
    const { auth } = useUser();
    const [data, setData] = useState([]);
    const [particular, setParticular] = useState("");
    const [percentage, setPercentage] = useState("");
    const [amount, setAmount] = useState("");
    const [billing, setBilling] = useState([]);
    const [pagereload, setPageReload] = useState(true);
    const [Ismode,setIsmode]=useState("negative");
    const [error, setError] = useState({
        particular: "",
        amount: "",
        percentage: "",
        mode:""
    });
    useEffect(() => {
        const fetchData = async () => {
            if (slug) {
                try {
                    const res1 = await axios.get(`${url}/viewdata/${slug}/${auth.id}_${"s_p_cn_dn"}/${auth.id}_stock/${table}`);
                    if (res1 && res1.data) {
                        const bill = JSON.parse(res1.data.bills);
                        setBilling(JSON.parse(bill.indireact_and_direact));
                    }
                } catch (error) {
                    console.error('Error fetching viewdata:', error);
                }
            }

            try {
                const res2 = await axios.get(`${url}/show_ledgers/${auth.id}_ledger`);
                if (res2 && res2.data) {
                    const filteredData = res2.data.filter(obj =>
                        ['direct-income', 'indirect-income', 'direct-expenses', 'indirect-expenses'].includes(obj.under)
                    );
                    setData(filteredData);
                }
            } catch (error) {
                console.error('Error fetching ledger data:', error);
            }
        };
        fetchData();
        
    },[slug, url, auth.id, table, pagereload]);

    const addData = () => {
        let errors = {};
        if (!particular) {
            errors.particular = "Particular is required.";
        }
        if (!percentage && !amount) {
            errors.percentage = "Percentage or amount is required.";
            errors.amount = "Percentage or amount is required.";
        }
        if(!Ismode){
            errors.mode = "Mode is required.";
        }

        setError(errors);

        if (Object.keys(errors).length === 0) {
            let billdata = [...billing, { particular: particular, percentage: percentage, amount: amount ,Ismode:Ismode}];
            setBilling(billdata);
            setParticular("");
            setPercentage("");
            setAmount("");
            setIsmode("negative");
            setError({ particular: "", amount: "", percentage: "" ,mode:""});
            onbilldata(billdata);

        }
    };
    const deleteData = () => {
        billing.pop();
        let billdata = [...billing];
        setBilling(billdata);
    };

    const [showFirstModal, setShowFirstModal] = useState(false);

    const handleFirstModalClose = () => setShowFirstModal(false);
    const handleFirstModalShow = () => setShowFirstModal(true);

    const Charges = (obj) => {
        setPageReload(!pagereload);
    };

    return (
        <div>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">Charges</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">Particulars</label>
                            <select className='form-control' value={particular} onChange={(e) => setParticular(e.target.value)}>
                                <option value={""}>{"--Select--"}</option>
                                {
                                    data && data.map((val, index) =>
                                        <option key={index} value={val.name}>{val.name}</option>
                                    )
                                }
                            </select>
                            {error.particular && <div className="text-danger">{error.particular}</div>}
                            <button className='btn btn-link' onClick={handleFirstModalShow}>+Client</button>
                            {/* <button className='btn btn-link'  data-bs-toggle="modal" data-bs-target="#debtoradd">+Client</button> */}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">Percentage</label>
                            <input type="number" className="form-control" value={percentage} onChange={(e) => setPercentage(e.target.value)} disabled={amount !== ""}></input>
                            {error.percentage && <div className="text-danger">{error.percentage}</div>}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">Amount</label>
                            <input type="number" className="form-control" value={amount} onChange={(e) => setAmount(e.target.value)} disabled={percentage !== ""}></input>
                            {error.amount && <div className="text-danger">{error.amount}</div>}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">Mode</label>
                            <div>
                                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value={"positive"} onChange={(e)=>setIsmode(e.target.value)}></input>
                                &nbsp;
                                <label class="form-check-label" for="flexRadioDefault1">
                                    Positive
                                </label>&nbsp;
                                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value={"negative"} onChange={(e)=>setIsmode(e.target.value)} checked={Ismode=="negative"?true:false}></input>
                                &nbsp;
                                <label class="form-check-label" for="flexRadioDefault1">
                                    Negative
                                </label>
                                {error.mode && <div className="text-danger">{error.mode}</div>}
                            </div>
                            {/* <label htmlFor="exampleInputEmail1" className="form-label">Positive</label>
                            <input type="radio" className="form-control" value={amount} onChange={(e) => setAmount(e.target.value)} disabled={percentage !== ""}></input>
                            <label htmlFor="exampleInputEmail1" className="form-label">Negative</label>
                            <input type="radio" className="form-control" value={amount} onChange={(e) => setAmount(e.target.value)} disabled={percentage !== ""}></input> */}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger" onClick={deleteData}>Delete</button>
                        <button type="button" className="btn btn-primary" onClick={addData}>Add</button>
                    </div>
                </div>
            </div>
            {/* <div class="modal fade" id="debtoradd" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <LedgerPop  url={url} onChildStateChange={Charges} />
            </div> */}
            <div className={`modal ${showFirstModal ? 'show' : ''}`} style={{ display: showFirstModal ? 'block' : 'none' }} tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Charge</h4>
                            <button type="button" className="btn-close text-reset" onClick={handleFirstModalClose}>&times;</button>
                        </div>
                        <div className="modal-body">
                            <LedgerPop url={url} onChildStateChange={Charges} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Direact_and_Indireact;
