import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ToWords } from 'to-words';
import LedgerPop from '../LedgerPop';
import Invoiceaddpanel from '../Invoiceaddpanel';
import GlobalKeyListener from '../../GlobalKeyListener';
import { useUser } from '../../Authdata';
import Debitnote_invoice_one from './Debitnote_invoice_one';
import Debitnote_invoice_two from './Debitnote_invoice_two';
import './DebitNoteInvoice.css';
function DebitNote(props) {
    const { auth } = useUser();
    // const setting=JSON.parse(localStorage.getItem('setting'))
    const [vocherdata, setVocherData] = useState(null);
    const [reloadpage, setReloadpage] = useState(0);
    const printref = useRef();
    const [bosNo, setBosNo] = useState(0);
    const [debNo, setDebNo] = useState(0);
    const [billing, setBilling] = useState([]);
    const [BillControler, setBillControler] = useState("cash");
    const [debtarname, SetDebtarname] = useState({});
    const [debtorname, setDebtorName] = useState([]);
    const redirect = useNavigate();
    const [datelocal, setDatelocal] = useState("");
    const [confirmsave, setConfirmSave] = useState(false);
    const [newbill, setNewBill] = useState(false)
    const [debitnote, setDabitnote] = useState("");
    const toWords = new ToWords();
    const [alldataIndireact_direact, setAlldataIndireact_direact] = useState([]);
    const [deleteIndex, setDeleteIndex] = useState(null);
    const [ledgerdata, setLedgerData] = useState([]);
    let params = useParams();
    var date = "";
    const [setting, setSetting] = useState(JSON.parse(localStorage.getItem("setting")));
    useEffect(() => {
        setSetting(JSON.parse(localStorage.getItem('setting')))
    }, [props.setting]);
    useEffect(() => {
        const callLedgerdata = async () => {
            let res = await axios.get(`${props.url}/show_ledgers/${auth.id}_ledger`);
            // console.log(res.data);
            setLedgerData(res.data);
        }
        callLedgerdata();
    }, [])
    const LedgerData = () => {
        setReloadpage(1 + reloadpage)
    }
    const billofsplly = (bn) => {
        // console.log(bn);
        setDebNo(bn.debno);

    }
    const returnBillno = (bn) => {
        setBosNo(bn);
    }
    let AddBilledata = (objs) => {
        // console.log(objs);
        // let billdata = [...billing, objs];
        setBilling(objs);
        setNewBill(false)
        // setBilling(objs);
    }
    let localdateset = (d) => {
        setDatelocal(d);
    }
    const newBill = () => {
        setNewBill(true)
        setConfirmSave(false)
    }
    const debitornamesetup = (name) => {
        if (name) {
            let obj = JSON.parse(name);
            SetDebtarname(obj && obj);
        }
    }
    const billLedgerSaleandPurchaseset = (val) => {
        setDabitnote(val)
    }
    let total = 0;
    let totalamount = 0;
    billing.forEach((da) => {
        total += da.Amout;
    })
    let discount = 0;
    alldataIndireact_direact.forEach((v) => {
        if (v.percentage != "") {
            discount = total * (v.percentage / 100)
            totalamount = total - Math.round(discount)

        } else {
            totalamount -= v.amount
        }
    })
    let wordstotal = toWords.convert(total);
    const printbill = useReactToPrint({
        content: () => printref.current
    })
    function SaveBilldata() {
        if (billing.length == 0) {
            toast.info("bill are empty")
        }
        else {
            let bill = JSON.stringify(billing);
            let ledger_dr = "";
            if (debtarname && debtarname.name && BillControler === "debtor") {
                ledger_dr = debtarname.id;
            } else {
                let cashLedger = ledgerdata.find((ledger) => ledger.name === "Cash");
                if (cashLedger) {
                    ledger_dr = cashLedger.id;
                }
            }
            let data = {
                date: datelocal,
                bills: JSON.stringify({ bill: bill }),
                total,
                frm_id: auth.id,
                bos_no: bosNo,
                ledger_dr,
                debit_no: debNo,
                slug: `debitnote-${debNo}`,
                ledger_cr: debitnote.id,
                under: "debitnote"
            }
            axios.post(`${props.url}/insert_debitnote/${auth.id}_s_p_cn_dn`, data).then((res) => {
                billing.forEach((bill) => {
                    let billdata = {
                        date: datelocal,
                        particulars: bill.pro_id,
                        rate: bill.rate,
                        quantity: bill.quaty,
                        unit: bill.unit,
                        total: bill.Amout,
                        frm_id: auth.id,
                        bill_id: `debitnote-${debNo}`,
                        voucher: "debitnote"
                    }
                    // console.log(billdata);
                    axios.post(`${props.url}/insert_stock/${auth.id}_stock`, billdata)

                })
                alldataIndireact_direact.forEach((info) => {
                    let indirect_and_direact = {
                        name: info.particular,
                        rate: info.percentage != "" ? discount : info.amount,
                        amount: totalamount != 0 ? totalamount : total,
                        hotel_id: auth.id,
                        under: debitnote,
                        bill: `debitnote-${debNo}`
                    }
                    axios.post(`${props.url}/accounts`, indirect_and_direact)
                })
                setConfirmSave(true);
                toast.success("save");
            })
        }
    }
    let UpdateBilldata = () => {
        let bill = JSON.stringify(billing);
        let ledger_dr = "";
        if (debtarname && debtarname.name) {
            ledger_dr = debtarname.name;
        }
        else {
            ledger_dr = "Cash";
        }
        let data = { date: datelocal, bill: JSON.stringify({ bill: bill }), total, hotel_id: auth.id, bos_no: bosNo, ledger_dr, debit_no: debNo, slug: `debitnote-${debNo}` }
        axios.post(`${props.url}/viewdataupdate/debit_note/${auth.id}/${params.slug}`, data).then((res) => {
            if (res.data) {
                redirect('/dashboard');
            }
        })
    }
    const CrandDr = (val) => {
        if (val) {
            let obj = JSON.parse(val);
            setDabitnote(obj)
        }
    }
    const UpdateVoucher = (objs) => {
        setVocherData(objs);
    }
    const Indireact_and_direact = (v) => {
        setAlldataIndireact_direact(v);
    }
    const billcontroler = (val) => {
        setBillControler(val)
    }
    const deleteItem = (index) => {
        setDeleteIndex(index)
    }
    return (
        <div>
            <GlobalKeyListener onSave={SaveBilldata} onPrint={printbill} onNew={newBill} />
            <div className='row text-center '>
                <div className='col-sm-2 border'>
                    <Invoiceaddpanel adbilldata={AddBilledata} url={props.url} settingcontrol={props.setting} localdate={localdateset} vocheri={"debitnote"} bosno={props.bosno} billNo={billofsplly} newbill={newbill} debitornamesetup={debitornamesetup} setupledcranddr={CrandDr} voucherupdate={UpdateVoucher} indireactanddireact={Indireact_and_direact} billLedgerSaleandPurchaseset={billLedgerSaleandPurchaseset} billcontroler={billcontroler} returnBillno={returnBillno} deletebillItem={deleteIndex} deleteItem={deleteItem} />
                </div>
                <div className={setting.invoice == "invoice-1" ? 'col-10 d-flex justify-content-center mt-5 mb-5' : "col-md-10"} >
                    <div >
                        <div class="text-center me-5 mx-5" ref={printref}>
                            {setting.invoice == "invoice-1" ? <Debitnote_invoice_one url={props.url} debNo={debNo} bosNo={bosNo} datelocal={datelocal} billing={billing} alldataIndireact_direact={alldataIndireact_direact} discount={discount} total={total} wordstotal={wordstotal} debtarname={debtarname} totalamount={totalamount} deleteIndex={deleteItem} invoiceAlignmentmeng={setting.invoiceAlignment} />
                                : <Debitnote_invoice_two url={props.url} bosNo={bosNo} debNo={debNo} datelocal={datelocal} billing={billing} alldataIndireact_direact={alldataIndireact_direact} discount={discount} total={total} wordstotal={wordstotal} debtarname={debtarname} totalamount={totalamount} deleteIndex={deleteItem} BillControler={BillControler} invoicdesc={setting.invoicedescription} invoiceAlignmentmeng={setting.invoiceAlignment} />
                            }
                            <div className="invoice-footer">
                                <p>This is a computer-generated invoice and does not require a signature.</p>
                            </div>
                        </div>
                        {
                            vocherdata ? <nav class="navbar navbar-expand-lg navbar-light bg-light border me-5 mx-5">
                                <div class="container-fluid">

                                    <button onClick={UpdateBilldata} className='btn btn-success' ><i class="bi bi-arrow-repeat"></i>Update</button>
                                    <Link to={"/dashboard"} className='btn btn-dark'><i class="bi bi-arrow-left-short"></i>Back</Link>
                                    <button onClick={printbill} className='btn btn-primary' id="printfun" ><i class="bi bi-printer"></i>Print</button>
                                </div>
                            </nav> : <nav class="navbar navbar-expand-lg navbar-light bg-light border me-5 mx-5">
                                <div class="container-fluid">

                                    <button onClick={SaveBilldata} className='btn btn-primary' disabled={confirmsave ? true : false}><i class="bi bi-floppy-fill"></i> Save</button>
                                    <button onClick={printbill} className='btn btn-primary' id="printfun" disabled={confirmsave ? false : true}><i class="bi bi-printer"></i> Print</button>
                                    <button className='btn btn-dark' onClick={() => newBill()} disabled={confirmsave ? false : true}><i class="bi bi-file-earmark-plus"></i> New</button>
                                    {/* <a href='' className='btn btn-dark' disabled>New</a> */}
                                </div>
                            </nav>
                        }



                    </div>
                    {/* <div class="modal fade" id="debtoradd" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <LedgerPop url={props.url} onChildStateChange={LedgerData}/>
                        </div> */}
                </div>
                <ToastContainer />
            </div>
        </div>
    )
}

export default DebitNote