import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { encryptData, decryptData } from '../encryptionUtils';
function Login(props) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState({
        email: "",
        password: ""
    })
    const redirect = useNavigate();
    useEffect(() => {
        const auth = localStorage.getItem("token");
        if (auth) {
            redirect('/dashboard');
        }
    }, [])

    const Login = async () => {
        let validationError = {};
        if (!email.trim() && !password.trim()) {
            validationError.email = "Email is required";
            validationError.password = "Password is required";
        } else if (!password.trim()) {
            validationError.password = "Password is required";
        } else if (!email.trim()) {
            validationError.email = "Email is required";
        }
        setError(validationError);
        if (Object.keys(validationError).length === 0) {
            let data = { email, password };
            const res = await axios.post(`${props.url}/login`, data);
            if (res.data != "Account is not active" && res.data != "Invalid credentials") {
                localStorage.setItem("token", JSON.stringify({ token: res.data.token }));
                // localStorage.setItem("user",encryptData(res.data.data));
                localStorage.setItem("setting", JSON.stringify({
                    bosno: "automatic",
                    invoiceAlignment:{
                        logo: "center",
                        firm_name: "center",
                        address: "center",
                        show_logo: "block",
                        show_firm_name: "block",
                        show_address: "block",
                        show_desc: "block"
                    }
                       
                }));
                redirect('/dashboard');
            }
            else if (res.data == "Account is not active") {
                toast.info('Account is not active');
            }
            else if (res.data == "Invalid credentials") {
                toast.error('Invalid Account');
            }


        }

    }
    return (
        <>
            <div className='container-fluid'>
                <div className='row mt-5 d-flex justify-content-center'>
                    <div className='col-md-5 '>
                        <div class="card ">
                            <div className='card-header d-flex bg-primary text-white'>
                                {/* <span class="navbar-brand text-uppercase me-5" href="#">Invoice Platform</span> */}
                                <span class="nav-link start-50" aria-current="page" href="">Login</span>
                            </div>
                            <div class="card-body">
                                <div class="mb-3">
                                    <label for="exampleInputEmail1" class="form-label">Email address</label>
                                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" onChange={(e) => setEmail(e.target.value)}></input>
                                    <div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div>
                                    <div>{error.email ? <span className='text-danger'>{error.email}</span> : null}</div>
                                </div>
                                <div class="mb-3">
                                    <label for="exampleInputPassword1" class="form-label">Password</label>
                                    <input type="password" class="form-control" id="exampleInputPassword1" onChange={(e) => setPassword(e.target.value)}></input>
                                    <div>{error.password ? <span className='text-danger'>{error.password}</span> : null}</div>
                                </div>

                                <button class="btn btn-primary" onClick={Login}>Login</button>
                            </div>
                            <ToastContainer />
                        </div>
                        <div className='row text-end' >
                            {/* <Link to={"/register"}>SignUp</Link> */}
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Login
