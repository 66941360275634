import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Login from './User/Login'
import Register from './User/Register';

function Home({ url }) {
    const [navRedirect,setNavRedirect]=useState("login");
    return (
        <div>
            <nav class="navbar navbar-expand-lg navbar-dark  bg-primary text-white">
                <div class="container-fluid">
                    <Link class="navbar-brand text-decoration-underline" href="/">Rupela.<span>in</span></Link>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarToggleExternalContent ">
                        <div className='navbar-nav me-auto mb-2 mb-lg-0'></div>
                        {/* <ul class="navbar-nav me-auto mb-2 mb-lg-0 ">
                            <li class="nav-item">
                                <Link class="nav-link active text-white" aria-current="page" href="#">Home</Link>
                            </li>
                            <li class="nav-item">
                                <Link class="nav-link text-white" href="#">About Us</Link>
                            </li>
                        </ul> */}
                        <div className='d-flex m-2'><Link className='nav-link'  onClick={()=>setNavRedirect("login")}>Login</Link></div>
                        <div className='d-flex m-2'><Link onClick={()=>setNavRedirect("register")} className='nav-link'>Register</Link></div>
                    </div>
                </div>
            </nav>
            {/* <div className='row'>
                <div className='col-12 text-center mt-5'>
                    <div className='container'>
                        <h1 className='text-uppercase'>Accounting <span className='text-primary'>Solution</span>  for Businesses</h1>
                        <p>
                            Rupela.in is an all-in-one accounting software designed to streamline your business's financial management. Whether you're handling sales, purchases, invoicing, or stock management, Rupela.in offers a user-friendly platform to manage it all. With comprehensive reporting, real-time balance updates, and seamless integration, our software is built to save you time and effort, allowing you to focus on growing your business.


                        </p>
                        <div className='m-2'>
                            <Link className='btn btn-primary' to={"/register"}>Get Started</Link>
                        </div>
                    </div>
                </div>

            </div>
            <div className='row bg-dark text-primary p-5'>
                <div className='col-12 text-center'>
                    <div className='container'>
                        <h1 className='text-uppercase'>
                            Manage Finances with Confidence and Ease
                        </h1>

                    </div>
                </div>

            </div> */}
            <div className='row m-5'>
                <div className='col-12 '>
                    {
                        navRedirect=="login"?<Login url={url} />:<Register url={url}/>
                    }
                    
                </div>

            </div>
            <footer class="bg-primary text-white text-center text-lg-start">
                <div class="container p-4">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 mb-0 mb-md-0">
                            <h5 class="text-uppercase">RUPELA.IN</h5>

                            <p>
                                Rupela.in is an accounting software designed to simplify financial management for businesses. It provides a range of features such as sales tracking, purchase management, invoicing, stock management, real-time balance updates, and comprehensive financial reporting.
                                {/* The goal of Rupela.in is to offer businesses, especially small and medium-sized enterprises, an easy-to-use platform that streamlines accounting processes, similar to solutions like Tally.
                                With a focus on efficiency, Rupela.in aims to reduce the time and complexity involved in managing day-to-day financial tasks, allowing businesses to focus on growth and operations without worrying about their accounts. It’s built with a user-friendly interface to make accounting accessible to users with varying levels of experience. */}
                            </p>
                        </div>

                        {/* <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
                            <h5 class="text-uppercase">Links</h5>

                            <ul class="list-unstyled mb-0">
                                <li>
                                    <a href="#!" class="text-white">Link 1</a>
                                </li>
                                <li>
                                    <a href="#!" class="text-white">Link 2</a>
                                </li>
                                <li>
                                    <a href="#!" class="text-white">Link 3</a>
                                </li>
                                <li>
                                    <a href="#!" class="text-white">Link 4</a>
                                </li>
                            </ul>
                        </div>

                        <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
                            <h5 class="text-uppercase mb-0">Links</h5>

                            <ul class="list-unstyled">
                                <li>
                                    <a href="#!" class="text-white">Link 1</a>
                                </li>
                                <li>
                                    <a href="#!" class="text-white">Link 2</a>
                                </li>
                                <li>
                                    <a href="#!" class="text-white">Link 3</a>
                                </li>
                                <li>
                                    <a href="#!" class="text-white">Link 4</a>
                                </li>
                            </ul>
                        </div> */}
                    </div>
                </div>

                <div class="text-center p-3" style={{ backgroundColor: "#00000005" }}>
                    {/* © 2020 Copyright: */}
                    {/* <a class="text-white" href="https://mdbootstrap.com/">MDBootstrap.com</a> */}
                </div>
            </footer>
        </div>
    )
}

export default Home
